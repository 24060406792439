<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        Ticket
        <v-icon>mdi-chevron-right</v-icon>
        จัดการ Ticket
      </v-card-title>
    </v-card>

    <!-- ticket -->
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      left
    >
      <v-tab> ticket </v-tab>
      <v-tab> ประเภท ticket </v-tab>
      <v-tab> สถานะ ticket </v-tab>

      <!-- ticket main -->
      <v-tab-item>
        <v-card class="v-card-margin-bottom-30">
          <v-card-title>
            <v-icon color="blue" size="24">fa-sliders-h</v-icon>
            <span class="ml-2">ค้นหาข้อมูล</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="6">
                <v-row class="pb-2">
                  <v-col class="pb-1" cols="12" md="4" lg="2"> วันที่สร้าง : </v-col>
                  <v-col class="pb-1" cols="12" md="8">
                    <v-row>
                      <v-col cols="6">
                        <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="300px" max-width="300px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateStart" outlined readonly label="เริ่มต้น"
                              prepend-inner-icon="mdi-calendar" @blur="startDate = parseDate(dateStart)" v-bind="attrs"
                              v-on="on" hide-details clearable :disabled="disableDate"
                              @click:clear="dateStart = null"></v-text-field>
                          </template>
                          <v-date-picker v-model="startDate" @input="menuStartDate = false" @change="
                            (dateStart = formatDate(startDate)),
                            CheckDateTime(0, startDate, endDate)
                            " no-title :allowed-dates="allowedDates"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-menu ref="menuEndDate" v-model="menuEndDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="300px" max-width="300px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateEnd" outlined readonly label="สิ้นสุด"
                              prepend-inner-icon="mdi-calendar" @blur="endDate = parseDate(dateEnd)" v-bind="attrs"
                              v-on="on" hide-details clearable :disabled="disableDate"
                              @click:clear="dateEnd = null"></v-text-field>
                          </template>
                          <v-date-picker v-model="endDate" @input="menuEndDate = false" @change="
                            (dateEnd = formatDate(endDate)),
                            CheckDateTime(0, startDate, endDate)
                            " no-title :allowed-dates="allowedDates"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pb-2">
                  <v-col class="pb-1" cols="12" md="4" lg="2"> สถานะ : </v-col>
                  <v-col class="pb-1" cols="12" md="8">
                    <v-autocomplete v-model="search.status" placeholder="เลือกสถานะ" :items="statusList"
                      item-text="statusName" item-value="statusID" outlined required hide-details dense></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="pb-1" cols="6">
                <v-row class="pb-2">
                  <v-col class="pb-1" cols="12" md="4" lg="2"> เปิดเคส : </v-col>
                  <v-col class="pb-1" cols="12" md="8">
                    <v-autocomplete v-model="search.createdBy" placeholder="เลือกพนักงาน" :items="staffList"
                      item-text="name" item-value="id" outlined required hide-details dense></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="pb-2">
                  <v-col class="pb-1" cols="12" md="4" lg="2"> มอบหมาย : </v-col>
                  <v-col class="pb-1" cols="12" md="8">
                    <v-autocomplete v-model="search.assignTo" placeholder="เลือกพนักงาน" :items="staffList"
                      item-text="name" item-value="id" outlined required hide-details dense></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="pb-2">
                  <v-col class="pb-1" cols="12" md="4" lg="3"> </v-col>
                  <v-col class="pb-1" cols="12" md="8">
                    <v-btn :elevation="1" class="pa-2 mt-1" color="primary" dark @click="getTicket()">
                      <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                      ค้นหา
                    </v-btn>
                    <v-btn :elevation="1" class="pa-2 ml-2 mt-1" outlined color="primary" @click="resetSearch()">
                      <v-icon size="14" class="pr-1">fa-solid fa-rotate</v-icon> รีเช็ต
                    </v-btn>

                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card elevation="1">
          <v-card-title class="height-57">
            <v-icon size="22" color="teal darken-3">fa fa-ticket</v-icon>
            <span class="ml-3">Ticket</span>
            <v-spacer />
            <v-btn
              :elevation="1"
              color="primary"
              class="px-2"
              @click="dialogTicket.dialog = true"
            >
              <v-icon size="22" class="mr-1">mdi-plus</v-icon>
              สร้าง Ticket
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <v-data-table
              :headers="ticketHeader"
              :items="ticketList"
              :items-per-page="9999999999"
              hide-default-footer
              class="packhai-border-table"
            >
              <template v-slot:item.ticketName="{ item }">
                <a @click="toTicketDetail(item)">{{item.ticketName}}</a>
              </template>
              <template v-slot:item.orderID="{ item }">
                {{ FormatOrder(item.orderID) }}
              </template>
              <template v-slot:item.relatedText="{ item }">
                {{item.relatedText.slice(3)}}
              </template>
              <template v-slot:item.id="{ item }">
                <a v-if="item.createdBy == staffID" @click="deleteTicket(item)">
                  <v-icon size="16" color="red darken-3">fa-trash</v-icon>
                </a>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- ticket type -->
      <v-tab-item>
        <v-card elevation="1">
          <v-card-title class="height-57">
            <v-icon size="22" color="teal darken-3">fa fa-receipt</v-icon>
            <span class="ml-3">ประเภท Ticket</span>
            <v-spacer />
            <v-btn
             @click="openDialogTypeTicket(0)"
              :elevation="1"
              color="primary"
              class="px-2"
            >
              <v-icon size="22" class="mr-1">mdi-plus</v-icon>
              เพิ่มประเภท
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <v-data-table
              :headers="typeHeader"
              :items="typeList"
              :items-per-page="9999999999"
              hide-default-footer
              class="packhai-border-table"
            >
              <template v-slot:item.typeID="{ item }">
                <a @click="openDialogTypeTicket(1, item)">
                  <v-icon size="16" color="orange darken-3">fa-edit</v-icon>
                </a>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- ticket status -->
      <v-tab-item>
        <v-card elevation="1">
          <v-card-title class="height-57">
            <v-icon size="22" color="teal darken-3">fa fa-file-alt</v-icon>
            <span class="ml-3">สถานะ Ticket</span>
            <v-spacer />
            <v-btn
             @click="openDialogStatusTicket(0)"
              :elevation="1"
              color="primary"
              class="px-2"
            >
              <v-icon size="22" class="mr-1">mdi-plus</v-icon>
              เพิ่มสถานะ
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <v-data-table
              :headers="statusHeader"
              :items="statusList"
              :items-per-page="9999999999"
              hide-default-footer
              class="packhai-border-table"
            >
              <template v-slot:item.statusID="{ item }">
                <a @click="openDialogStatusTicket(1, item)">
                  <v-icon size="16" color="orange darken-3">fa-edit</v-icon>
                </a>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <!-- dialog ticket main -->
    <v-dialog v-model="dialogTicket.dialog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3">fa fa-receipt</v-icon>
           <span class="ml-3">สร้าง Ticket</span>
          <v-spacer/>
          <v-btn icon @click="closeDialogTicket()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto px-5">
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> ชื่อ Ticket : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-text-field
                :value="dialogTicket.name"
                @change="value => dialogTicket.name = Trim_value(value)"
                ref="dialogTicketName"
                autocomplete="off"
                outlined
                dense hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> ร้านค้า : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-autocomplete 
                v-model="dialogTicket.shopID" placeholder="เลือกร้านค้า (ไม่จำเป็น)" :items="shopList"
                item-text="name" item-value="id" outlined required hide-details dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> รายละเอียด : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-textarea
                :value="dialogTicket.description"
                @change="value => dialogTicket.description = Trim_value(value)"
                ref="dialogTicketDesc"
                autocomplete="off"
                outlined
                dense hide-details
                rows="3"
                row-height="9999"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> ประเภท : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-autocomplete 
                v-model="dialogTicket.ticketType" placeholder="เลือกประเภท" :items="typeList"
                item-text="typeName" item-value="typeID" outlined required hide-details dense
                ref="dialogTicketType"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> สถานะ : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-autocomplete 
                v-model="dialogTicket.status" placeholder="เลือกสถานะ" :items="statusList"
                item-text="statusName" item-value="statusID" outlined required hide-details dense
                ref="dialogTicketStatus"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> มอบหมาย : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-autocomplete 
                v-model="dialogTicket.assignTo" placeholder="เลือกพนักงาน" :items="staffList"
                item-text="name" item-value="id" outlined required hide-details dense
                ref="dialogTicketAssign"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> ผู้ที่เกี่ยวข้อง : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-autocomplete
                v-model="dialogTicket.relateList"
                :items="staffList"
                placeholder="เลือกพนักงาน"  
                item-text="name"
                item-value="id"
                multiple 
                outlined
                hide-details
                ref="dialogTicketRelate"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="toggle">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="selectAllStaff"
                        :color="selectSomeStaff ? 'primary' : undefined"
                        :indeterminate="selectSomeStaff && !selectAllStaff"
                      >
                      </v-checkbox>
                    </v-list-item-action>
                    <v-list-item-title>เลือกทั้งหมด</v-list-item-title>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template> 
                
                <template v-slot:selection="{ item, index }">

                  <span v-if="index < 1">{{ item.name }}</span>
                  <span v-else-if="index < 3">, {{ item.name }} </span>
                  <span v-if="index === 3" class="text-grey text-caption align-self-center"> (+{{
                    dialogTicket.relateList.length - 3 }} ตัวเลือกอื่น)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> ออเดอร์ : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-text-field
                :value="dialogTicket.orderID"
                @change="value => dialogTicket.orderID = Trim_value(value)"
                ref="dialogTicketOrder"
                autocomplete="off"
                outlined
                dense hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> แนบไฟล์/รูป : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-btn
                onclick="document.getElementById('fileInput').click()"
                outlined
                color="primary"
              >
                แนบไฟล์
              </v-btn>
              <v-file-input
                id="fileInput"
                v-model="myFile"
                @change="setDataFile()"
                style="display: none"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="(item,index) in preFile" :key="index" class="col-3">
              <div class="d-flex">
                <v-img v-if="item.type == 'image/jpeg' || item.type == 'image/png'" class="mt-3 title mb-1" :src="item.url"/>
                <v-icon v-else size="120" class="mt-3 mb-1" color="teal darken-3">fa fa-file-alt</v-icon>
                <v-btn icon color="danger" @click="removeFile(item)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row  class="item-center">
            <v-col align="right">
              <v-btn :elevation="1" color="primary" @click="btnSubmitTicket()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog ticket type -->
    <v-dialog v-model="dialogType.dialog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3">fa fa-receipt</v-icon>
           <span v-if="dialogType.type == 0" class="ml-3">เพิ่มประเภท</span>
           <span v-if="dialogType.type == 1" class="ml-3">แก้ไขประเภท</span>
          <v-spacer/>
          <v-btn icon @click="closeDialogTypeTicket()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto px-5">
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> ชื่อประเภท : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-text-field
                :value="dialogType.name"
                @change="value => dialogType.name = Trim_value(value)"
                ref="dialogType"
                autocomplete="off"
                outlined
                dense hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row  class="item-center">
            <v-col align="right">
              <v-btn :elevation="1" color="primary" @click="btnSubmitType()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog ticket status -->
    <v-dialog v-model="dialogStatus.dialog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3">fa fa-file-alt</v-icon>
           <span v-if="dialogStatus.type == 0" class="ml-3">เพิ่มสถานะ</span>
           <span v-if="dialogStatus.type == 1" class="ml-3">แก้ไขสถานะ</span>
          <v-spacer/>
          <v-btn icon @click="closeDialogStatusTicket()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto px-5">
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> ชื่อสถานะ : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-text-field
                :value="dialogStatus.name"
                @change="value => dialogStatus.name = Trim_value(value)"
                ref="dialogStatus"
                autocomplete="off"
                outlined
                dense hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> ลำดับสถานะ : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-text-field
                :value="dialogStatus.orderID"
                @change="value => dialogStatus.orderID = Trim_value(value)"
                @keypress="isNumberWNoDot($event)"
                ref="dialogStatusOrder"
                autocomplete="off"
                outlined
                dense hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row  class="item-center">
            <v-col align="right">
              <v-btn :elevation="1" color="primary" @click="btnSubmitStatus()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { header_token } from "@/website/token";
import Loading from "@/website/components/Loading";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import { Trim_value, ChangeOrderId, FormatOrder, isNumberWNoDot } from "@/website/global_function";
import { shopService_dotnet, branchService_dotnet, generalService_dotnet } from "../../global";
export default {
  name: 'FrontWarehouseTicket',

  components: {
    Loading,
  },

  data() {
    return {
      tab: null,
      branchID: JSON.parse(localStorage.getItem("Branch_BranchID")),
      staffID: JSON.parse(localStorage.getItem("Branch_StaffID")),
      header_token: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("authorization_token"),
      },
  
      //info for create ticket
      shopList: [],
      staffList: [],
      myFile: null,
      preFile: [],
      insertFile: [],

      //ticket main
      // ตามวันที่
      search: {
        status: null,
        createdBy: null,
        assignTo: null,
      },
      startDate: null,
      dateStart: null,
      menuStartDate: false,
      endDate: null,
      dateEnd: null,
      menuEndDate: false,
      disableDate: false,
      
      ticketList: [],
      ticketHeader: [
        { align: "center", text: "No", value: "no", sortable: false, width: "60px" },
        { align: "left", text: "ชื่อเรื่อง", value: "ticketName", sortable: false, width: "200px" },
        { align: "left", text: "เลขออเดอร์", value: "orderID", sortable: false, width: "150px" },
        { align: "left", text: "รายละเอียด", value: "description", sortable: false, width: "250px" },
        { align: "left", text: "ร้านค้า", value: "shopName", sortable: false, width: "150px" },
        { align: "left", text: "ประเภท", value: "typeName", sortable: false, width: "150px" },
        { align: "left", text: "สถานะ", value: "statusName", sortable: false, width: "150px" },
        { align: "left", text: "มอบหมาย", value: "staffName", sortable: false , width: "150px"},
        { align: "left", text: "ผู้เกี่ยวข้อง", value: "relatedText", sortable: false, width: "200px" },
        { align: "left", text: "สร้างเมื่อ", value: "created", sortable: false, width: "150px" },
        { align: "center", text: "ลบ", value: "id", sortable: false, width: "80px" },
      ],
      dialogTicket: {
        dialog: false,
        type: 0,
        name: null,
        id: null,
        description: null,
        shopID: null,
        ticketType: null,
        status: null,
        assignTo: null,
        relateList: [],
        orderID: null,
        fileList: []
      },

      //ticket type
      typeList: [],
      typeHeader: [
        { align: "center", text: "No", value: "orderBy", sortable: false, width: "60px" },
        { align: "left", text: "ประเภท", value: "typeName", sortable: false },
        { align: "center", text: "แก้ไข", value: "typeID", sortable: false, width: "80px" },
      ],
      dialogType: {
        dialog: false,
        type: 0,
        name: null,
        id: null,
      },

      //ticket status
      statusList: [],
      statusHeader: [
        { align: "center", text: "No", value: "orderBy", sortable: false, width: "60px" },
        { align: "left", text: "สถานะ", value: "statusName", sortable: false },
        { align: "center", text: "แก้ไข", value: "statusID", sortable: false, width: "80px" },
      ],
      dialogStatus: {
        dialog: false,
        type: 0,
        name: null,
        id: null,
        orderID: null
      },

      // paging
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 10,
      offsets: 0,

      // loading
      page_loading: true,
      loading: false,
    };
  },

  watch: {

  },
  
  async created() {
    await this.getTicket()
    await this.getShop()
    await this.getStaff()
    await this.getTicketType()
    await this.getTicketStatus()
    this.page_loading = false;
    this.loading = false
  },

  computed: {
    selectAllStaff() {
      return this.dialogTicket.relateList.length === this.staffList.length
    },
    selectSomeStaff() {
      return this.dialogTicket.relateList.length > 0
    },
  },
  
  methods: {
    Trim_value,
    ChangeOrderId,
    FormatOrder,
    isNumberWNoDot,

    //#region get info
    async getShop() {
      let response = await axios.post(
        shopService_dotnet + "Shop/get-shop-list",
        {
          shopType: 1,
          branchId: this.branchID,
        },
        { headers: this.header_token }
      );
      this.shopList = response.data.result.map((item) => { return {id: item.shop.id, name: item.shop.name} })
    },
    async getStaff() {
      let response = await axios.post(
        shopService_dotnet + "StaffBranch/get-staffbranch-list",
        {
          branchId: this.branchID,
        },
        { headers: header_token }
      );
      this.staffList = response.data
    },
    setDataFile() {
      if (this.myFile != null) {
        var url = URL.createObjectURL(this.myFile);
        if (this.myFile.type == "image/png" || this.myFile.type == "image/jpeg") {
          this.preFile.push({url: url, name: this.myFile.name, type:this.myFile.type, file: this.myFile})
        } else {
          this.preFile.push({url: url, name: this.myFile.name, type:this.myFile.type, file: this.myFile })
        }
      }
    },
    removeFile(img) {
      // if (info.name != 0) {
      //   this.removeList.push(info.id)
      // }
      this.preFile = this.preFile.filter(function(item) {
        return item !== img
      })
      this.myFile = null
    },
    async uploadFile(file) {
      var form_data = new FormData();
      form_data.append("file", file);
      form_data.append("bucket", "branch/ticket");
      let response = await axios.post(
        generalService_dotnet + "General/upload-file-with-bucket",
        form_data,
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.insertFile.push(response.data.file_path);
      }
    },

    toggle() {
      if (this.selectAllStaff) {
        this.dialogTicket.relateList = []
      } else {
        this.dialogTicket.relateList = []
        this.dialogTicket.relateList = this.staffList.map(x => x.id)
      }
    },

    //#endregion get info


    //#region ticket main
    async getTicket() {
      let response = await axios.post(
        branchService_dotnet + "Ticket/get-ticket",
        {
          branchID: this.branchID,
          statusID: this.search.status,
          createdBy: this.search.createdBy,
          assignTo: this.search.assignTo,
          startDate: this.startDate,
          endDate: this.endDate,
          // skip: 0,
          // take: 99999
        },
        { headers: header_token }
      );
      this.ticketList = response.data.itemList
      this.total = response.data.totalRows
    },

    resetSearch() {
      this.search = {
        status: null,
        createdBy: null,
        assignTo: null,
      }
      this.startDate = null
      this.dateStart = null
      this.menuStartDate = false
      this.endDate = null
      this.dateEnd = null
      this.menuEndDate = false
      this.disableDate = false
    },

    closeDialogTicket() {
      this.dialogTicket = {
        dialog: false,
        type: 0,
        name: null,
        id: null,
        description: null,
        shopID: null,
        ticketType: null,
        status: null,
        assignTo: null,
        relateList: [],
        orderID: null,
        fileList: []
      }
      this.myFile = null
      this.preFile = []
      this.insertFile = []
    },

    async btnSubmitTicket() {
      if(this.dialogTicket.name == null || this.dialogTicket.name == '') {
        this.$refs.dialogTicketName.focus()
        this.errorAlert("กรุณากรอกหัวข้อ")
        return
      } else if(this.dialogTicket.description == null || this.dialogTicket.description == '') {
        this.$refs.dialogTicketDesc.focus()
        this.errorAlert("กรุณากรอกรายละเอียด")
        return
      } else if(this.dialogTicket.ticketType == null) {
        this.$refs.dialogTicketType.focus()
        this.errorAlert("กรุณาเลือกประเภท")
        return
      } else if(this.dialogTicket.status == null) {
        this.$refs.dialogTicketStatus.focus()
        this.errorAlert("กรุณาเลือกสถานะ")
        return
      } else if(this.dialogTicket.assignTo == null) {
        this.$refs.dialogTicketAssign.focus()
        this.errorAlert("กรุณาเลือกพนักงาน")
        return
      } else if(this.dialogTicket.relateList.length == 0) {
        this.$refs.dialogTicketRelate.focus()
        this.errorAlert("กรุณาเลือกพนักงาน")
        return
      } else if(this.dialogTicket.orderID.length == 0) {
        this.$refs.dialogTicketOrder.focus()
        this.errorAlert("กรุณากรอกเลขออเดอร์")
        return
      } 

      for (let index = 0; index < this.preFile.length; index++) {
        await this.uploadFile(this.preFile[index].file)
      }

      let response = await axios.post(
        branchService_dotnet + "Ticket/create-ticket",
        {
          "ticketName": this.dialogTicket.name,
          "branchID": this.branchID,
          "shopID": this.dialogTicket.shopID,
          "typeID": this.dialogTicket.ticketType,
          "orderID": ChangeOrderId(this.dialogTicket.orderID),
          "description": this.dialogTicket.description,
          "statusID": this.dialogTicket.status,
          "assignTo": this.dialogTicket.assignTo,
          "createdBy": this.staffID,
          "relatedID": this.dialogTicket.relateList,
          "file": this.insertFile
        },
        { headers: header_token }
      );

      if (response.status == 200) {
        await this.getTicket()
        this.successAlert()
        this.closeDialogTicket()
      }
      
    },
    async deleteTicket(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        text: item.ticketName + " ลบ ใช่ หรือ ไม่",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          let response = await axios.post(
            branchService_dotnet + "Ticket/remove-ticket",
            { "ticketID": item.id },
            { headers: header_token }
          );
          if (response.status == 200) {
            await this.getTicket()
            this.successAlert()
          }
        }
      });
    },

    toTicketDetail(item) {
      window.open("ticket-detail?id=" + btoa(item.id), "_blank");
    },

    //#endregion ticket main


    //#region ticket type
    async getTicketType() {
      let response = await axios.post(
        branchService_dotnet + "Ticket/get-ticket-type",
        {
          branchID: this.branchID,
        },
        { headers: header_token }
      );
      this.typeList = response.data
    },

    openDialogTypeTicket(type, item) {
      this.dialogType.dialog = true
      this.dialogType.type = type
      if (type == 1) {
        this.dialogType.id = item.typeID
        this.dialogType.name = item.typeName
      }
    },

    closeDialogTypeTicket() {
      this.dialogType = { dialog: false, type: 0, name: null, id: null }
    },

    async btnSubmitType() {
      if(this.dialogType.name == null || this.dialogType.name == '') {
        this.$refs.dialogType.focus()
        this.errorAlert("กรุณากรอกชื่อประเภท")
        return
      }

      let response = await axios.post(
        branchService_dotnet + "Ticket/manage-ticket-type",
        {
          typeID: this.dialogType.type == 0 ? 0 : this.dialogType.id,
          branchID: this.branchID,
          typeName: this.dialogType.name
        },
        { headers: header_token }
      );
      if (response.data == "success") {
        await this.getTicketType()
        this.closeDialogTypeTicket()
      }
    },

    //#endregion ticket type

    //#region ticket status
    async getTicketStatus() {
      let response = await axios.post(
        branchService_dotnet + "Ticket/get-ticket-status",
        {
          branchID: this.branchID,
        },
        { headers: header_token }
      );
      this.statusList = response.data
    },
    openDialogStatusTicket(type, item) {
      this.dialogStatus.dialog = true
      this.dialogStatus.type = type
      if (type == 1) {
        this.dialogStatus.id = item.statusID
        this.dialogStatus.name = item.statusName
        this.dialogStatus.orderID = item.orderBy
      }
    },

    closeDialogStatusTicket() {
      this.dialogStatus = { dialog: false, type: 0, name: null, id: null, orderID: null }
    },

    async btnSubmitStatus() {
      if(this.dialogStatus.name == null || this.dialogStatus.name == '') {
        this.$refs.dialogStatus.focus()
        this.errorAlert("กรุณากรอกชื่อสถานะ")
        return
      }
      if (this.dialogStatus.type == 1) {
        if(this.dialogStatus.orderID == null || this.dialogStatus.orderID == '') {
          this.$refs.dialogStatusOrder.focus()
          this.errorAlert("กรุณากรอกลำดับ")
          return
        }
        if (Number(this.dialogStatus.orderID) > this.statusList.length || Number(this.dialogStatus.orderID) == 0) {
          this.$refs.dialogStatusOrder.focus()
          this.errorAlert("กรอกลำดับไม่ถูกต้อง")
          return
        }
      }

      let response = await axios.post(
        branchService_dotnet + "Ticket/manage-ticket-status",
        {
          statusID: this.dialogStatus.type == 0 ? 0 : this.dialogStatus.id,
          orderID: this.dialogStatus.type == 0 ? 0 : this.dialogStatus.orderID,
          branchID: this.branchID,
          statusName: this.dialogStatus.name
        },
        { headers: header_token }
      );
      if (response.data == "success") {
        await this.getTicketStatus()
        this.closeDialogStatusTicket()
      }
    },
    //#endregion ticket status

    //#region alert
    async successAlert(){
      Swal.fire({
        toast: true,
        timer: 1500,
        showConfirmButton: false,
        type: 'success',
        title: 'Success'
      })
    },
    async errorAlert(body){
      Swal.fire({
        type: 'error',
        text: body,
      })
    },
    //#endregion alert

    //fn format
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    CheckDateTime(index, startDate, endDate) {
      if (startDate != null && endDate != null) {
        var PstartDate = startDate;
        var PendDate = endDate;
        if (new Date(startDate) > new Date(endDate)) {
          PstartDate = endDate;
          PendDate = startDate;
        }
        startDate = PstartDate;
        endDate = PendDate;
      }
      if (index == 0) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.dateStart = this.formatDate(startDate);
        this.dateEnd = this.formatDate(endDate);
      }
      if (index == 1) {
        this.startDate2 = startDate;
        this.endDate2 = endDate;
        this.dateStart2 = this.formatDate(startDate);
        this.dateEnd2 = this.formatDate(endDate);
      }
      if (index == 2) {
        this.startDate3 = startDate;
        this.endDate3 = endDate;
        this.dateStart3 = this.formatDate(startDate);
        this.dateEnd3 = this.formatDate(endDate);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>

</style>